import { useState } from 'react'

interface popupProps {
	onSubmit: (email: string) => void
}

function SignUpForm(objForm: popupProps) {
	const [email, setEmail] = useState('')

	function handleSubmit(e: React.FormEvent) {
		e.preventDefault()
		objForm.onSubmit(email)
	}

	return (
		<form onSubmit={handleSubmit}>
			<div>
				<p className="tilted">GET NOTIFIED OF NEW SONGS</p>
				<p id="mainContent">We present tales<br/>
				of victory, defeat and heartbreak<br/>
				in a CAPITALIST society<br/>
				through pop, R&B and metal music</p>
				<div style={{position:"relative"}}>
					<input style={{width:"calc(95% - 61px)",height:"40px",paddingLeft:"49px",fontSize:"10pt"}}
						type="email"
						id="email"
						value={email}
						placeholder='E-mail'
						onChange={e => setEmail(e.target.value)}
						required
					/>
					<img src="assets/mail.svg" style={{position:"absolute",left:"23px",top:"7px",width:"32px"}}/>
				</div>
			</div>
			<button className="btnSubmit" type="submit">Sign Up</button>
		</form>
	)
}

export default SignUpForm