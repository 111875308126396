import { useState } from 'react'

import AudioPlayer from 'react-h5-audio-player'
import 'react-h5-audio-player/lib/styles.css'

const playlist = [
	["Ghosted","assets/corporation_swine-ghosted.mp3","assets/marcus_psycho.jpg"],
	["Fight 2 Survive 4 Love","assets/corporation_swine-fight_to_survive_for_love.mp3","assets/karate_training.jpg"],
	["Break Me","assets/corporation_swine-break_me.mp3","assets/marcus_spector.jpg"],
	["Hostile Takeover","assets/corporation_swine-hostile_takeover.mp3","assets/wallstreet.jpg"],
	["Pay Day","assets/corporation_swine-pay_day.mp3","assets/factory.jpg"]
]

export function Player() {
	const [currentSongIdx, setCurrentSongIdx] = useState(1)

	function songNext() {
		let newSongIdx = currentSongIdx+1 
		if (newSongIdx === playlist.length) newSongIdx = 0
		setCurrentSongIdx(newSongIdx)
	}
	
	function songPrevious() {
		let newSongIdx = currentSongIdx-1
		if (newSongIdx < 0) newSongIdx = playlist.length-1
		setCurrentSongIdx(newSongIdx)
	}

	
	return(
		<div id="playerContainer">
			<div id="songCaption">
				<img style={{width:"36%"}} src={playlist[currentSongIdx][2]}/>
				<p style={{fontSize:"24pt",display:"inline-flex",margin:"auto"}}>{playlist[currentSongIdx][0]}</p>
			</div>
			<AudioPlayer
				autoPlay
				showSkipControls={true}
				src={playlist[currentSongIdx][1]}
				onEnded={songNext}
				onClickNext={songNext}
				onClickPrevious={songPrevious}
				volume={0.5}
			/>
		</div>
	)
}