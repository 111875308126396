interface popupProps {
	onSubmit: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export function SentPopup(submitCallback: popupProps) {
	return(
		<>
			<p className="tilted">THANK YOU FOR SIGNING UP</p>
			<p style={{fontSize:"13pt",lineHeight:"33px"}}>
				Soon we'll be trying to sell you stuff you don't need<br/>
				like a book teaching you how to grow your revenue<br/>
				which only helps to grow our revenue<br/>
				but it'll make you feel like you've been productive<br/>
				...and there will actually be music coming
			</p>
			<button className="btnSubmit" onClick={submitCallback.onSubmit}>Whatever</button>
		</>
	)
}

export function ErrorPopup(errorCallback: popupProps) {
	return(
		<>
			<p className="tilted">ERROR SENDING FORM</p>
			<p style={{fontSize:"13pt",lineHeight:"33px"}}>
				Something went wrong<br/>
				and its definitely not our fault<br/>
				but rest assured<br/>
				someone will be losing their job over it<br/>
			</p>
			<button className="btnSubmit" onClick={errorCallback.onSubmit}>Whatever</button>
		</>
	)
}
