import { useEffect, useState } from 'react'
import Modal from "./components/Modal"
import SignUpForm from './components/SignUpForm'
import { Player } from './components/Player'
import { ErrorPopup, SentPopup } from './components/Popups'
import './App.css'

function App() {
	const [showForm, setShowForm] = useState(true)
	const [showSent, setShowSent] = useState(false)
	const [showError, setShowError] = useState(false)

	async function handleSignUp(email: string) {
		console.log('Signed up with email:', email)
		setShowForm(false)
		const settings = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				email: email,
			})
		}		
		try {
			const response = await fetch("https://punkoffice.com/punkoffice_api/corpswine", settings)
			try {
				const json = await response.json()
				if (json.success === 1) {
					setShowSent(true)
				} else {
					setShowError(true)
				}
			} catch (error: unknown) {
				if (error instanceof Error) {
					setShowError(true)
				}
			}
		} catch (error: unknown) {
			if (error instanceof Error) {
				setShowError(true)
			}
		}
	}

	function closeSentPopup() {
		setShowSent(false)
	} 

	function closeErrorPopup() {
		setShowError(false)
		setShowForm(true)
	}

	useEffect(() => {
		console.log("Re-render APP")
	})

	return (
		<div className="App">
			<Modal show={showForm} onClose={() => setShowForm(false)}>
				<SignUpForm onSubmit={handleSignUp} />
			</Modal>				
			<Modal show={showSent} onClose={() => setShowSent(false)}>
				<SentPopup onSubmit={closeSentPopup} />				
			</Modal>
			<Modal show={showError} onClose={() => setShowSent(false)}>
				<ErrorPopup onSubmit={closeErrorPopup} />				
			</Modal>
			<div id="logo"/>
			<div id="main"/>
			<Player/>
		</div>
	)
}

export default App;
