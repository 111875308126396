import styled from 'styled-components'

const ModalBackground = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2;
`;

const ModalContent = styled.div`
	background: rgba(55,55,55,0.8);
	padding: 20px;
	border-radius: 5px;
	max-width: 350px;
	width: 80%;
	outline: 2px solid #977052;
	outline-width: thick;
	outline-style: double;

	@media only screen and (max-height: 600px) {
		position: absolute;
		bottom: 30px;
	}	

	@media only screen and (max-height: 440px) {
		bottom: 10px;
	}
`;

interface ModalProps {
	show: boolean
	onClose: () => void
	children: React.ReactNode
}

function Modal({ show, onClose, children }: ModalProps) {
	if (!show) {
		return null
	}

  	return (
		<ModalBackground>
			<ModalContent onClick={e => e.stopPropagation()}>
				{children}
			</ModalContent>
		</ModalBackground>
  	)
}

export default Modal